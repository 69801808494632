<template>
  <div class="row">
    <div class="col-12 pb-1">
      <button id="lmxta-finalizacaoNFCe-btn-enviaremail" class="btn btn-secondary col-12" @click="StatusEmail.Formulario = true">
        Enviar DANFE por E-mail
        <i class="far fa-paper-plane fa-fw"></i>
      </button>
    </div>

    <div class="col-12">
      <button
        id="lmxta-finalizacaoNFCe-btn-imprimir"
        v-show="!StatusDanfe.Baixando"
        class="btn btn-default form-control"
        @click="imprimirDanfe"
      >
        Imprimir DANFE
        <i class="fas fa-print fa-fw"></i>
      </button>

      <button v-show="StatusDanfe.Baixando" class="btn btn-default form-control" disabled="disabled">
        Imprimir DANFE
        <i class="fas fa-circle-notch fa-spin"></i>
      </button>
    </div>
    <envio-email-danfe
      :nfce="false"
      :idNFe="notaGerada.IdNFe"
      v-if="StatusEmail.Formulario"
      @fechar="StatusEmail.Formulario = false"
    />
  </div>
</template>

<script>
  import swal from '@/common/alerts';
  import NFeService from '@/core/services/nfe.service';
  import EnvioEmailDanfe from '@/components/shared/finalizacao/EnvioEmailDanfe.vue';
  import { baixarArquivoPdf } from '@/common/files';

  export default {
    props: {
      notaGerada: Object,
    },
    components: {
      EnvioEmailDanfe,
    },
    data() {
      return {
        StatusEmail: {
          Enviando: false,
          Formulario: false,
          Valido: true,
          Dica: false,
        },
        StatusDanfe: {
          Baixando: false,
        },
        NFeService: NFeService,
      };
    },

    methods: {
      imprimirDanfe() {
        this.StatusDanfe.Baixando = true;
        this.NFeService.imprimirDanfe(this.notaGerada)
          .then((result) => {
            baixarArquivoPdf(result, this.notaGerada.Chave, true);
          })
          .catch((error) => {
            swal.exibirMensagemErro('Ocorreu um problema ao imprimir.');
          })
          .finally(() => (this.StatusDanfe.Baixando = false));
      },
    },
  };
</script>

<style></style>
