<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="pb-3 text-center">
        <img class="lx-icon-md" src="@/assets/Images/linx-icon-ilustrativo/check-circle.png" alt="check" />
        <span class="title text-truncate">
          NF
          <strong>{{ NotaGerada.DocumentoGerado }}</strong>
          gerada!
        </span>

        <span class="d-block fz-95">Total da Nota</span>
        <strong class="d-block">{{ NotaGerada.TotalNota | money }}</strong>

        <span class="d-block fz-95">Cliente</span>
        <strong class="d-block">{{ NotaGerada.Cliente }}</strong>

        <span class="d-block fz-95">Natureza de Operação</span>
        <strong class="d-block">{{ NotaGerada.NaturezaOperacao }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      NotaGerada: Object,
    },
  };
</script>

<style></style>
