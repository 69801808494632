<template>
  <div class="row justify-content-center" id="container-rejeicao">
    <div class="col-12 p-3">
      <div class="shadow-sm bg-white shadow-hover panel card-panel">
        <div col="col-md-12">
          <div class="text-center">
            <img src="@/assets/Images/linx-icon-ilustrativo/alert.png" alt="check" />

            <p class="pt-1 mb-1" v-if="existeDocumento">
              <span class="title text-truncate">
                NF
                <strong>{{ DocumentoGerado }}</strong>
                gerada!
              </span>
            </p>

            <p class="title text-truncate pt-1">Não foi possível concluir a devolução.</p>
          </div>

          <div class="row col text-center">
            <p class="mb-1 ml-3 list-group-item-action">
              <strong v-if="Rejeicao.RejeicaoSefaz">A SEFAZ rejeitou a sua nota de devolução.</strong>
              <strong v-else>Não foi possível enviar a nota para a SEFAZ</strong>
            </p>

            <p class="mb-1 ml-3 list-group-item-action">
              Rejeição:
              <strong class="fz-95">
                <span class="pr-1" v-show="Rejeicao.Codigo">({{ Rejeicao.Codigo }})</span>
                <span>{{ Rejeicao.Mensagem }}</span>
              </strong>
            </p>
            <p class="mb-1 ml-3 list-group-item-action" v-show="Rejeicao.Complemento">
              Complemento:
              <strong class="fz-95">{{ Rejeicao.Complemento }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      Rejeicao: Object,
      DocumentoGerado: {
        type: String,
        required: false,
        default: '',
      },
    },
    computed: {
      existeDocumento() {
        return this.DocumentoGerado != '';
      },
    },
  };
</script>
<style scoped></style>
