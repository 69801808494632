import store from '@/store/index';

const NFeService = {
  parametrosGeracao(getter) {
    return getter('nfe');
  },

  autorizar(request) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('devolucao/autorizarNFe', { Identificador: request.Id })
        .then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          let rejeicao = {};
          if (error && error.response) {
            rejeicao = error.response.data;
            rejeicao.Status = error.response.status;
            rejeicao.Mensagem = rejeicao.Detalhes;

            if (rejeicao.Complemento) {
              rejeicao.Complemento = rejeicao.Complemento.replaceAll('\\n', '');
            }
          } else {
            rejeicao.Codigo = 'M01';
            rejeicao.Mensagem = 'Não foi possível comunicar com a API de autorização NF-e.';
          }

          return reject(rejeicao);
        });
    });
  },

  imprimirDanfe(request) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('devolucao/obterDanfeNFe', request.IdNFe)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          return reject(error.response.data);
        });
    });
  },

  inutilizarAposExclusao() {
    return false;
  },

  exigeAutorizacao() {
    return true;
  },
};

export default NFeService;
