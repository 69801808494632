<template>
  <p class="mb-1 ml-3 list-group-item-action">
    <span class="pr-1">
      <i :class="icone"></i>
    </span>
    <span>{{ descricao }}</span>
  </p>
</template>

<script>
  export default {
    props: {
      descricao: String,
      falha: Boolean,
      sucesso: Boolean,
      pendente: Boolean,
    },

    computed: {
      icone() {
        if (this.sucesso) {
          return 'fa fa-check fa-1x fa-fw i-success';
        }

        if (this.falha) {
          return 'fa fa-times fa-1x fa-fw i-danger';
        }

        if (this.pendente) {
          return 'fas fa-circle-notch fa-spin i-primary';
        }

        return '';
      },
    },
  };
</script>
