<template>
  <BaseModal
    v-if="true"
    :telaInteira="false"
    :tituloModal="'a'"
    :classesAdicionaisModal="'rounded modal-pesquisa rounded overflow-x-hidden w-60 w-sm-100 position-relative'"
    :classesAdicionaisHeader="'d-none'"
    :classesAdicionaisFooter="'d-none'"
    :classesAdicionaisBody="'p-0'"
  >
    <div slot="body" class="bg-dark">
      <div class="shadow-sm bg-white shadow-hover panel card-panel scroll">
        <div class="col">
          <div class="text-center">
            <img src="@/assets/Images/linx-icon-ilustrativo/envelope.png" alt="check" />
            <p class="title text-truncate pt-1">Enviar {{ descricaoNomeDocumento }} por e-mail</p>
          </div>

          <div class="row">
            <div class="col-12 pb-1">
              <div class="form-group">
                <label for="email-danfe">E-mail p/ envio da DANFE/{{ descricaoNomeDocumento }}.</label>
                <input
                  type="text"
                  v-model="Emails"
                  name="email-danfe"
                  placeholder="destinatario@email.com"
                  :class="{ 'form-control': true, 'is-invalid': !EmailValido }"
                  id="lmxta-envemail-input-emails"
                  v-on:keyup.enter="enviarEmail"
                />

                <div class="invalid-feedback">Informe um e-mail válido.</div>
              </div>

              <p class="mb-0">
                <a href="#" @click="MostraDica = !MostraDica">
                  <i class="fas fa-question-circle"></i>
                  <small>
                    Clique aqui e veja situações que podem fazer com que o destinatário não receba o DANFE/{{
                      descricaoNomeDocumento
                    }}
                    por e-mail.
                  </small>
                </a>
              </p>

              <ul class="pl-4" v-show="MostraDica">
                <li>
                  <small>O e-mail informado não está correto ou não existe.</small>
                </li>
                <li>
                  <small> A caixa de e-mails do destinatário está lotada e não permite a entrada de novas mensagens. </small>
                </li>
                <li>
                  <small>
                    O destinatário possui um serviço de bloqueio de mensagens de remetentes desconhecidos (anti-spam).
                  </small>
                </li>
              </ul>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col text-left">
              <button class="btn btn-default rounded" id="lmxta-envemail-btn-voltar" @click="voltar">Voltar</button>
            </div>
            <div class="col text-right">
              <button
                class="btn btn-primary rounded ml-2"
                id="lmxta-envemail-btn-confirmar"
                @click="enviarEmail"
                :disabled="EnviandoEmail"
              >
                Enviar
                <span v-if="EnviandoEmail">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
  import swal from '@/common/alerts';

  export default {
    name: 'EnvioEmail',
    components: {},

    props: {
      identificadorId: {
        type: String,
        required: false,
      },
      emailCliente: {
        type: String,
        required: false,
      },
      nfce: {
        type: Boolean,
        required: true,
      },
      idNFe: {
        type: Number,
        required: false,
      },
      listIdNFe: {
        type: Array,
        required: false,
        default: () => [],
      },
    },

    data: function () {
      return {
        Emails: '',
        MostraDica: false,
        EmailValido: true,
        EnviandoEmail: false,
      };
    },

    computed: {
      descricaoNomeDocumento() {
        return this.nfce ? 'NFC-e' : 'NF-e';
      },
    },

    watch: {
      emailCliente() {
        this.Emails = this.emailCliente;
      },
    },

    mounted: function () {
      if (this.emailCliente) {
        this.Emails = this.emailCliente;
      }
    },

    methods: {
      voltar: function () {
        this.$emit('fechar');
      },

      enviarEmail() {
        this.EmailValido = this.validarEmail(this.Emails);
        if (!this.EmailValido) {
          return false;
        }

        this.EnviandoEmail = true;

        if (this.nfce) {
          this.enviarEmailNFCe();
        } else if (this.listIdNFe.length) {
          this.enviarListaDeEmail();
        } else {
          this.enviarEmailNFe();
        }
      },

      enviarEmailNFe() {
        this.$store
          .dispatch('devolucao/enviarEmailNFe', {
            IdNFe: this.idNFe,
            ListaEmails: this.Emails,
          })
          .then((response) => {
            this.exibirSucessoEnvioEmail();
          })
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            this.exibirFracassoEnvioEmail(mensagemErro);
            return Promise.reject(mensagemErro);
          });
      },

      enviarListaDeEmail() {
        let errosEnvioEmail = [];
        this.listIdNFe.forEach((idNfe) => {
          this.$store
            .dispatch('devolucao/enviarEmailNFe', {
              IdNFe: idNfe,
              ListaEmails: this.Emails,
            })
            .then()
            .catch((erro) => {
              let mensagemErro = '';

              if (erro.response) {
                if (erro.response.status == 400) mensagemErro = erro.data;
                else mensagemErro = erro.message;
              } else mensagemErro = erro;

              errosEnvioEmail.push(mensagemErro);
            });
        });

        if (errosEnvioEmail.length) {
          this.exibirFracassoEnvioEmail(errosEnvioEmail.join('<br>'));
          return;
        }
        this.exibirSucessoEnvioEmail();
      },

      enviarEmailNFCe() {
        return;
        this.$store
          .dispatch('atendimento/EnviarEmailNFCe', {
            Id: this.identificadorId,
            Destinatario: this.Emails,
          })
          .then((response) => {
            this.exibirSucessoEnvioEmail();
          })
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            this.exibirFracassoEnvioEmail(mensagemErro);
            return Promise.reject(mensagemErro);
          });
      },
      exibirSucessoEnvioEmail() {
        swal.exibirMensagemSucesso(
          'E-mail enviado com sucesso!',
          (params) => {
            setTimeout(() => {
              this.EnviandoEmail = false;
              this.voltar();
            }, 500);
          },
          true,
        );
      },

      exibirFracassoEnvioEmail(error) {
        this.EnviandoEmail = false;
        swal.exibirMensagemErro('Ocorreu um problema ao enviar o e-mail.' + (error ? '<br /><br />' + error : ''));
      },

      validarEmail(email) {
        const re =
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },
    },
  };
</script>

<style scoped>
  .only-overflow-y {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .home-container-body {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>
