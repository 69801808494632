<template>
  <div class="row justify-content-center" id="container-falha">
    <div class="col-md-12 p-3">
      <div class="shadow-sm bg-white shadow-hover panel card-panel">
        <div>
          <div class="text-center">
            <img src="@/assets/Images/linx-icon-ilustrativo/alert.png" alt="check" />

            <p class="title text-truncate pt-1">Não foi possível emitir a nota de devolução.</p>
          </div>

          <div class="row text-center">
            <ul>
              <li v-for="(msg, indice) in MensagensErro" :key="indice">{{ msg }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      MensagensErro: Array,
    },
  };
</script>
