<template>
  <button id="btn-doc-interno" class="btn btn-default btn-block" @click="abrirDocumentoInterno">
    Documento interno
    <i class="fas fa-file-alt" />
  </button>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: {
      identificador: String,
    },
    computed: {
      ...mapGetters('devolucao', ['baseUrlErp']),
      urlDocumentoInterno() {
        return this.baseUrlErp + '/faturamento/imprime_doc.asp?identificador=' + this.identificador;
      },
    },
    methods: {
      abrirDocumentoInterno() {
        window.open(this.urlDocumentoInterno, '_blank');
      },
    },
  };
</script>
